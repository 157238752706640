import { faArrowRight, faFireAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Flavors = () => {
  const data = useStaticQuery(graphql`
    query Flavors {
      allSanityFlavors(sort: { fields: heat }) {
        nodes {
          heat
          id
          description
          name
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Flavors" />

      <div className="flex justify-center">
        <div className="container flex flex-col justify-center my-20">
          <h2 className="text-4xl text-gray-800 tracking-wide leading-9 font-bold text-center">
            Find the perfect wing flavors.
          </h2>
          <p className="text-center mt-2 text-lg font-medium">
            And enjoy a cold one. You know you want to.
          </p>
          {/* <div className="flex justify-center mt-12 text-gray-800">
            <div className="bg-white flex rounded shadow w-2/3 p-4">
              <div style={{ margin: "auto" }}>
                <div className="text-xl font-bold mb-2">Heat Scale</div>
                <div></div>
              </div>
              <div>
                <div className="text-xl font-bold text-center mb-2">
                  Flavor Type
                </div>
                <div className="flex justify-around">
                  <div>Wet</div>
                  <div>Dry</div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex justify-center flex-wrap m-20">
            {data?.allSanityFlavors?.nodes?.map?.(flavor => {
              const heat = Math.trunc(flavor.heat)

              return (
                <div className="bg-white rounded-lg shadow-lg p-4 w-96 text-gray-800 flex flex-col justify-between m-6">
                  <div className="text-center text-3xl mb-4">
                    {Array(heat)
                      .fill()
                      .map((_, i) => (
                        <FontAwesomeIcon
                          key={`fire-${i}`}
                          icon={faFireAlt}
                          className={`${
                            heat !== 5 ? "text-yellow-600" : "text-red-600"
                          } mr-2`}
                        />
                      ))}
                    {heat < 5 &&
                      Array(5 - heat)
                        .fill()
                        .map((_, i) => (
                          <FontAwesomeIcon
                            key={`dud-${i}`}
                            icon={faFireAlt}
                            className="mr-2 text-gray-300"
                          />
                        ))}
                  </div>
                  <h4 className="text-2xl font-semibold text-center italic">
                    {flavor.name}
                  </h4>
                  <p className="text-center leading-6 text-gray-600">
                    {flavor.description}
                  </p>
                  <a
                    href="https://leftyswingsandgrill.alohaorderonline.com/"
                    className="bg-blue-600 hover:bg-blue-500 rounded shadow-lg text-white px-6 w-full py-4 mt-6 font-bold text-2xl block text-center justify-self-end"
                  >
                    Order Takeout{" "}
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Flavors
